<template>
  <div class="welcome">
    <img src="../../assets/welcome.jpeg" alt="" width="606px" height="539px" />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.welcome {
  width: 100%;
  height: calc(100vh - 120px);
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  user-select: none;
}
</style>
